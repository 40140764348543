
import { defineComponent, PropType, toRefs, ref, reactive, watch } from 'vue'
import { getMenu, prepareMenuForRouting} from '@/composables/menu'
import { MenuStructure } from '@/contracts/templates/MenuContracts'
import { store } from '@/./store'

export default defineComponent(
{
    name: 'MenuComponent',
    props:
    {
        type: {
            type: String,
            default: 'main'
        },
        items: {
            type: Array as PropType<Array<MenuStructure>>,
            default: new Array<MenuStructure>()
        }
    },
    data()
    {
        return {
            // showMenu: false,
            // menuToDisplay: new Array<MenuStructure>()
        }
    },
    setup(props)
    {
        // Reactive props:
        const { type, items } = toRefs(props)
        // Reactive menu data:
        const menuData = ref(items.value)
        // Reactive show menu bool:
        const showMenu = ref(true)
        // Reactive menu to display array:
        const menuToDisplay = ref(items.value)
        // Check if menu is static:
        if(type.value !== 'static')
        {
            showMenu.value = false
            // Record menu to load:
            store.commit('recordMenuToLoad')
            // Get menu:
            const menu = getMenu(type.value)
            // Watch menu data:
            watch(menu.data, () => menuData.value = menu.data.value)
        }
        // Return data:
        return {
            menuData,
            showMenu,
            menuToDisplay
        }
    },
    watch:
    {
        menuData(newMenuData: Array<MenuStructure>)
        {
            // Update menu:
            this.updateMenu(newMenuData)
            this.menuToDisplay = newMenuData
        },
        items(newItems)
        {
            // Check if items exist:
            if(newItems.length > 0)
            {
                // Display items:
                this.menuToDisplay = newItems
            }
            else
            {
                // Display default menu:
                this.menuToDisplay = this.menuData
            }
        }
    },
    computed:
    {
        // Return the active route name:
        activeRouteName(): string|null|undefined|symbol
        {
            return this.$route.name
        }
    },
    methods:
    {
        // Get route name:
        getRouteName(item: MenuStructure)
        {
            // If item is external:
            if(item.link_type_id === 2)
            {
                return { name: 'NotFound' }
            }
            // If item is using page path:
            else if(item.page && item.path === '')
            {
                return { name: item.page.name }
            }
            // If item has it's own path:
            else
            {
                return { name: item.name + 'Menu' }
            }
        },
        // Update menu:
        updateMenu(menu: Array<MenuStructure>)
        {
            // Generate menu elements:
            prepareMenuForRouting(this.$router, menu)
            // Show menu:
            this.showMenu = true
            // Record laoded menu:
            this.$store.commit('recordMenuLoaded')
        }
    }
});
