import axiosGet from '@/composables/api'
import { ApiResponse } from '@/contracts/api/ResponseContracts'
import { RouteRecordRaw } from 'vue-router'
import { MenuStructure } from '@/contracts/templates/MenuContracts'

// Get menu from API:
export function getMenu(type = 'main'): ApiResponse
{
    let url

    switch(type)
    {
        case 'secondary':
            url = '/menu/secondary'
            break;
        case 'footer':
            url = '/menu/footer'
            break;
        case 'main':
        default:
            url = '/menu/main'
    }

    return axiosGet(url)
}

// Generate array of route records for router:
export function prepareMenuForRouting(router: any, menu: Array<MenuStructure>, prependToUrl = '', view = 'Page'): Array<RouteRecordRaw>
{
    // Hold array of routes generated:
    const routeRecordsArray: Array<RouteRecordRaw> = []
    // Loop through the menu:
    menu.forEach((item) =>
    {
        // Check if menu item has children:
        if('children' in item && item.children !== undefined)
        {
            const childView = (item.children_template !== undefined ? item.children_template : view)
            // Generate menu elements for the children:
            prepareMenuForRouting(router, item.children, item.path, childView)
        }
        // Determine if this is a page or menu item by checking for page id:
        if(item.page_id === undefined)
        {
            // Set the page id as the page's id:
            item.page_id = item.id
        }
        // Only set a route record if item isn't an external link:
        if(item.link_type_id === undefined ||
                (item.link_type_id === 1 &&
                 item.path !== '' &&
                 item.page !== undefined &&
                 item.page.is_contentless == false))
        {
            // Create the route record:
            const routeRecord: RouteRecordRaw =
            {
                path: prependToUrl + item.path,
                component: () => import('@/views/' + view + 'View.vue'),
                name: (item.link_type_id === undefined ? item.name : item.name + 'Menu'),
                redirect: '',
                props: item,
                children: []
            }
            // Add route record into array:
            routeRecordsArray.push(routeRecord)
            // Add route to router:
            router.addRoute(routeRecord)
        }
    })
    // Return the route records array:
    return routeRecordsArray
}
