import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { store, key } from './store'
import { createMetaManager, plugin as metaPlugin } from 'vue-meta'
import  VueGoogleMaps from '@fawmi/vue-google-maps'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import VueCookies from 'vue-cookies'

import "bootstrap"
import "@/scss/app.scss"

const metaManager = createMetaManager()

const cookieData = {
    cookies: [
        {
            title: 'Necessary',
            description: 'Necessary cookies are required to enable the core functionality of the site such as security or adjusting your consent preferences. These cookies do not store any personally identifiable data. You may disable these by changing your browser settings, but this may affect how the website functions.', 
            isOptional: false,
            optOutName: '',
            optOutDuration: null,
            items: [
                {
                    source: 'Google reCaptcha',
                    value: '_GRECAPTCHA',
                    duration: 'End of browser session',
                    description: 'Prevents spam for form submissions.'
                }
            ]
        },
        {
            title: 'Analytics',
            description: 'Analytical cookies are used to understand how visitors interact with the website. These cookies help provide information on metrics such as the number of visitors, bounce rate, traffic source, etc.',
            isOptional: true,
            optOutName: 'analytics',
            optOutDuration: 90,
            items: [
                { 
                    source: 'Google Analytics', 
                    value: '_ga',
                    duration: '2 years',
                    description: 'The _ga, _gat_UA-<id> & _ga_<container-id> cookie, installed by Google tag manager for Google Analytics, calculates visitor, session and campaign data and also keeps track of site usage for the site\'s analytics report. The cookie stores information anonymously and assigns a randomly generated number to recognize unique visitors.<br><a href="https://support.google.com/analytics/answer/6004245#zippy=%2Cgoogle-analytics-cookies-and-identifiers" target="_blank">Read more from Google about safeguarding your data</a>.'
                },
                { 
                    source: 'Google Analytics', 
                    value: '_gi',
                    duration: '6 months',
                    description: 'The _gi* cookie, installed by Google Analytics, calculates visitor, session and campaign data and also keeps track of site usage for the site\'s analytics report. The cookie stores information anonymously and assigns a randomly generated number to recognize unique visitors.<br><a href="https://support.google.com/analytics/answer/6004245#zippy=%2Cgoogle-analytics-cookies-and-identifiers" target="_blank">Read more from Google about safeguarding your data</a>.'
                },
                { 
                    source: 'Google Analytics', 
                    value: '__ut',
                    duration: '6 months',
                    description: 'The __ut* cookie, installed by Google Analytics, calculates visitor, session and campaign data and also keeps track of site usage for the site\'s analytics report. The cookie stores information anonymously and assigns a randomly generated number to recognize unique visitors.<br><a href="https://support.google.com/analytics/answer/6004245#zippy=%2Cgoogle-analytics-cookies-and-identifiers" target="_blank">Read more from Google about safeguarding your data</a>.'
                },
            ]
        },
        // {
        //     title: 'Functional',
        //     description: 'Functional cookies are required to enable the core functionality of the site such as security or adjusting your consent preferences. These cookies do not store any personally identifiable data. You may disable these by changing your browser settings, but this may affect how the website functions.', 
        //     isOptional: true,
        //     optOutName: 'functional',
        //     optOutDuration: 30,
        //     items: [
        //         {
        //             source: 'Functional',
        //             value: '_example',
        //             duration: 'End of browser session',
        //             description: 'Description...'
        //         }
        //     ]
        // },
    ]
}

createApp(App)
    .use(store, key)
    .use(router)
    .use(metaManager)
    .use(metaPlugin)
    .use(VueGoogleMaps, {load: {key: 'AIzaSyDs-az6pMlttsXEQCerJYPa5AYPKAiJKBc'}})
    .use(VueReCaptcha, {siteKey: '6Lfd5SUfAAAAABW7_qDjNlDW96kTqraRTl-qflZl', loaderOptions: {useRecaptchaNet: true, autoHideBadge: true}})
    .use(VueCookies)
    .provide('cookieData', cookieData)
    .mount('#app')
