
import { defineComponent } from 'vue';

export default defineComponent(
    {
        name: 'NotFound',
        props:
        {
            code: {
                type: [String, Number],
                default: '404'
            },
            message: {
                type: String,
                default: 'Page not found...'
            }
        }
    });
