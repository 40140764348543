
import { defineComponent } from 'vue'
import MenuComponent from './MenuComponent.vue'
import { MenuStructure, MenuProps } from '@/contracts/templates/MenuContracts'

export default defineComponent(
{
    name: 'SecondaryMenu',
    components:
    {
        MenuComponent
    },
    props:
    {
        showSecondaryMenu: Boolean,
        secondaryMenu: Array,
        mobile: {
            type: Boolean,
            default: false
        }
    },
    data()
    {
        return {
            localShowSecondaryMenu: false
        }
    },
    computed: {
        getClassString(): string
        {
            if(this.mobile)
            {
                return 'secondary-menu mobile'
            }
            return 'secondary-menu'
        }
    },
    created()
    {
        this.localShowSecondaryMenu = this.showSecondaryMenu
    },
    watch:
    {
        showSecondaryMenu(newShowSecondaryMenu)
        {
            this.localShowSecondaryMenu = newShowSecondaryMenu
        }
    },
    methods:
    {
        // Get route name:
        getRouteName(item: MenuStructure)
        {
            // If item is using page path:
            if(item.page && item.path === '')
            {
                return item.page.name
            }
            // If item has it's own path:
            else
            {
                return item.name + 'Menu'
            }
        },
        // Return href:
        getHref(props: MenuProps)
        {
            // Check if link type is internal:
            if(props.item.link_type_id == 2)
            {
                return props.item.url
            }
            return props.href
        },
        // Return target:
        getTarget(item: MenuStructure)
        {
            // Check if link type is external:
            if(item.link_type_id === 2 && item.open_in_new_tab)
            {
                return '_blank'
            }
            return '_self'
        },
        // On navigate:
        onNavigate(props: MenuProps, event: MouseEvent)
        {
            // Check if link type is external:
            if(props.item.link_type_id == 1)
            {
                event.preventDefault()
                props.navigate()
            }
            if(!this.mobile)
            {
                this.localShowSecondaryMenu = false
            }
        }
    }
});
