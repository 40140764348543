import { createRouter, createWebHistory, RouteRecordRaw, RouterScrollBehavior } from 'vue-router'
import { store } from '@/./store'
import SitemapXml from '../views/SitemapXml.vue'
import HealthCheck from '../views/HealthCheck.vue'
import NotFound from '../views/NotFound.vue'

// Hold routes:
const routes: Array<RouteRecordRaw> =
[
    { path: '/sitemap', name: 'SitemapXML', component: SitemapXml, props: true },
    { path: '/health-check', name: 'AzureHealthCheck', component: HealthCheck, props: true },
    { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound, props: true }
]

const scrollBehavior: RouterScrollBehavior = (to, from) => {
    if(to.name === from.name)
    {
        return false
    }
    return { left: 0, top: 0, behavior: 'smooth' }
}

// Start router:
const router = createRouter(
{
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior
})

// Before route is resolved:
router.beforeResolve((route) =>
{
    // Log in non-production:
    if(process.env.NODE_ENV !== 'production')
    {
        console.log('BeforeResolve:', route)
    }
    // Clear router errors before we resolve:
    store.commit('clearRouterError')
    // Only resolve if routes/menu have been loaded:
    if(!store.getters.menuState)
    {
        store.commit('setRouterQuery', route.query)
        return false
    }
})
// Before each route:
router.beforeEach(() =>
{
    store.commit('startProgressEntity')
})
// After each route:
router.afterEach(() =>
{
    store.commit('finishProgressEntity')
    store.commit('recordRoutingCompletion')
})

export default router
