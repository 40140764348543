
import { defineComponent } from 'vue'
import { useMeta } from 'vue-meta'
import ProgressComponent from '@/ProgressComponent.vue'
import HeaderComponent from '@/HeaderComponent.vue'
import FooterComponent from '@/FooterComponent.vue'
import VideoComponent from '@/components/elements/VideoComponent.vue'
import axiosGet from '@/composables/api'
import { MenuStructure } from '@/contracts/templates/MenuContracts'
import { CookieDataStructure, CookieStructure, CookieItemStructure } from '@/contracts/templates/CookieContracts'
import { prepareMenuForRouting} from '@/composables/menu'
import { inject } from 'vue'

export default defineComponent(
{
    components:
    {
        ProgressComponent,
        HeaderComponent,
        FooterComponent,
        VideoComponent
    },
    data()
    {
        return {
            routesLoaded: false,
            showBanner: false,
            cookieName: '',
            optIns: [] as any[]
        }
    },
    setup()
    {
        const cookieData = inject('cookieData') as CookieDataStructure
        // Set meta:
        const { meta } = useMeta(
        {
            title: 'McLaren Applied',
        })
        // Get pages:
        const pages = axiosGet('/pages')
        //Return data:
        return {
            cookieData,
            pages: pages.data
        }
    },
    computed:
    {
        // Return menu loaded state:
        menuLoaded()
        {
            return this.$store.getters.menuState
        }
    },
    mounted()
    {
        this.cookieData['cookies'].forEach((category: CookieStructure, index) => {
            if (category.optOutName !== "") {
                this.optIns.push(category.optOutName);
            }
        });

        this.checkCookies()
    },
    watch:
    {
        // On menu loaded state change:
        menuLoaded(newState)
        {
            // Check state is loaded:
            if(newState === true)
            {
                this.$router.push({ path: window.location.pathname, query: this.$store.getters.queryState, replace: true })
            }
        },
        // On pages changes:
        pages(newPages)
        {
            this.setupRouting(newPages)
        },
        $route (to, from){
            this.checkCookies()
        }
    },
    methods:
    {
        checkCookies()
        {
            let loopCookiesTimer = 0;
            const cookiesAreCleared = false
            const loopCookies = setInterval(() => 
            {
                if(cookiesAreCleared || loopCookiesTimer >= 10)
                {
                    clearInterval(loopCookies);
                }

                const getCookieConf = this.$cookies.get('cookieConf');
                const allCookies = this.$cookies.keys();

                if (!getCookieConf) {
                    this.showBanner = true;
                }
                // if (getCookieConf === null) {
                //     this.showBanner = false;
                // }
                allCookies.forEach(cookie => {
                    if (cookie.startsWith('opt-out-')) {
                        this.cookieName = cookie.replace('opt-out-', '');
                        this.cookieData['cookies'].forEach((category: CookieStructure) => {
                            if (category.optOutName === this.cookieName) {
                                if (category.items) {
                                    category.items.forEach((value: CookieItemStructure) => {
                                        allCookies.forEach(el => {
                                            // Find 'startsWith' matches and delete
                                            if (el.startsWith(value.value)) {
                                                if (process.env.VUE_APP_API_DOMAIN === 'https://cmsapi.mclarenapplied.com/api') {
                                                    this.$cookies.remove(el, '/', '.mclarenapplied.com');
                                                } else if (process.env.VUE_APP_API_DOMAIN === 'https://app-website-cms-devtest-001.azurewebsites.net/api') {
                                                    this.$cookies.remove(el, '/', '.app-website-pub-devtest-001.azurewebsites.net');
                                                } else {
                                                    this.$cookies.remove(el);
                                                }
                                            }
                                        });
                                    });
                                }
                            }
                        });
                    }
                })
                loopCookiesTimer++
            }, 200)
        },

        // Setup routing:
        setupRouting(pages: Array<MenuStructure>)
        {
            // Generate routes:
            prepareMenuForRouting(this.$router, pages)
            // Set routes as loaded:
            this.routesLoaded = true
        },

        onCloseBanner()
        {
            // Set cookieAccept cookie
            // Initial accept = 180 days
            const expiry = new Date;
            expiry.setDate(expiry.getDate() + 180);
            this.$cookies.set('cookieConf', this.optIns, expiry);
            this.showBanner = false
        }
    }
});
