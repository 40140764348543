
import { defineComponent } from 'vue';
import NProgress from 'nprogress';

export default defineComponent(
{
    name: 'ProgressComponent',
    mounted()
    {
        NProgress.configure({ easing: 'ease', speed: 500, showSpinner: false })
    },
    computed:
    {
        // Return progresss state:
        progressValue()
        {
            return this.$store.getters.progressState
        }
    },
    watch:
    {
        // On progress state change:
        progressValue(newValue, oldValue)
        {
            if(newValue < 0 || oldValue < 0)
            {
                this.$store.commit('resetProgressEntity')
                NProgress.done()
            }
            else if(newValue === 0)
            {
                NProgress.done()
            }
            else if(oldValue === 0)
            {
                NProgress.start()
            }
            else
            {
                NProgress.inc();
            }
        }
    }
});
