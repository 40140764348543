
import { defineComponent } from 'vue';
import { MenuStructure } from '@/contracts/templates/MenuContracts'
import MainMenu from '@/components/menus/MainMenu.vue';
import SecondaryMenu from '@/components/menus/SecondaryMenu.vue';

export default defineComponent(
{
    name: 'HeaderComponent',
    components:
    {
        MainMenu,
        SecondaryMenu
    },
    props:
    {
        menuLoaded: Boolean
    },
    data()
    {
        return {
            showSecondaryMenu: false,
            expandMobileNav: false,
            secondaryMenu: new Array<MenuStructure>(),
            test: false
        }
    },
    computed:
    {
        // Return routing completion state:
        routingCompletion()
        {
            return this.$store.getters.routingCompletion
        }
    },
    watch:
    {
        // Watch for routing completion:
        routingCompletion(newValue, oldValue)
        {
            // On trigger mobile menu close when menu is expanded and we're after intial load routing:
            if((this.$refs.menuToggler as HTMLElement).classList.length == 1 && newValue > 2)
            {
                (this.$refs.menuToggler as HTMLElement).click()
            }
        }
    },
    methods:
    {
        resetSecondaryMenu()
        {
            this.displaySecondaryMenu([])
        },
        displaySecondaryMenu(children: Array<MenuStructure>)
        {
            this.secondaryMenu = children
            this.showSecondaryMenu = true
        },
        hideSecondaryMenu()
        {
            this.showSecondaryMenu = false
        },
        toggleSecondaryMenu()
        {
            this.showSecondaryMenu = !this.showSecondaryMenu
        }
    }
});
