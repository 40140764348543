
import { defineComponent } from 'vue';
import MenuComponent from './MenuComponent.vue'
import { MenuStructure, MenuProps } from '@/contracts/templates/MenuContracts'

export default defineComponent(
{
    name: 'FooterMenu',
    components:
    {
        MenuComponent
    },
    methods:
    {
        // Return href:
        getHref(props: MenuProps)
        {
            // Check if link type is internal:
            if(props.item.link_type_id == 2)
            {
                return props.item.url
            }
            return props.href
        },
        // Return target:
        getTarget(item: MenuStructure)
        {
            // Check if link type is external:
            if(item.link_type_id === 2 && item.open_in_new_tab)
            {
                return '_blank'
            }
            return '_self'
        },
        // On navigate:
        onNavigate(props: MenuProps, event: MouseEvent)
        {
            // Check if link type is external:
            if(props.item.link_type_id == 1)
            {
                event.preventDefault()
                props.navigate()
            }
        }
    }
});
