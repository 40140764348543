import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = ["href", "onClick", "target"]
const _hoisted_3 = { class: "link-container" }
const _hoisted_4 = ["href", "onClick", "target"]
const _hoisted_5 = { class: "link-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuComponent = _resolveComponent("MenuComponent")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.getClassString)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_MenuComponent, {
        type: "secondary",
        items: _ctx.secondaryMenu
      }, {
        default: _withCtx((slotProps) => [
          _createElementVNode("li", null, [
            (slotProps.activeRouteName === _ctx.getRouteName(slotProps.item))
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  class: "active",
                  href: _ctx.getHref(slotProps),
                  onClick: ($event: any) => (_ctx.onNavigate(slotProps, $event)),
                  target: _ctx.getTarget(slotProps.item)
                }, [
                  _createElementVNode("div", _hoisted_3, _toDisplayString(slotProps.item.name), 1)
                ], 8, _hoisted_2))
              : (_openBlock(), _createElementBlock("a", {
                  key: 1,
                  href: _ctx.getHref(slotProps),
                  onClick: ($event: any) => (_ctx.onNavigate(slotProps, $event)),
                  target: _ctx.getTarget(slotProps.item)
                }, [
                  _createElementVNode("div", _hoisted_5, _toDisplayString(slotProps.item.name), 1)
                ], 8, _hoisted_4))
          ])
        ]),
        _: 1
      }, 8, ["items"])
    ])
  ], 2)), [
    [_vShow, _ctx.localShowSecondaryMenu]
  ])
}