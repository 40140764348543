
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent(
{
    name: 'SitemapXml',
    setup()
    {
        // Load router:
        const router = useRouter()
        // Setup sitemap:
        const setupSitemap = function()
        {
            // Get routes and domain:
            const routes = router.getRoutes()
            const domain = window.location.host
            // Set route names to ignore:
            const ignore =
            [
                'SitemapXML',
                'AzureHealthCheck',
                'NotFound'
            ]
            // Hold XML output:
            let xmlOutput = ''
            // Map routes:
            routes.map(route => 
            {
                const name = String(route.name)
                if(route.name && !ignore.includes(name))
                {
                    xmlOutput += `<url><loc>${domain}${route.path}</loc><changefreq>monthly</changefreq><priority>0.5</priority></url>`
                    route.children.map(children =>
                    {
                        xmlOutput += `<url><loc>${domain}${route.path}${children.path}</loc><changefreq>monthly</changefreq><priority>0.5</priority></url>`
                    }).join('\r\n')
                }
            }).join('\r\n')
            // Return XML string:
            return `<?xml version="1.0" encoding="UTF-8"?><urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9 http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd">${xmlOutput}</urlset>`
        }
        // Create blob with sitemap data:
        const blob = new Blob([setupSitemap()], { type: 'application/xml' })
        // Generate link to blob:
        const link = URL.createObjectURL(blob)
        // Open link:
        window.location.replace(link)
        // window.open(link)
    }
});
