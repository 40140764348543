import { InjectionKey, Ref } from 'vue'
import { createStore, useStore as baseUseStore, Store } from 'vuex'

export interface State
{
    routerQuery: Record<string, string>,
    menusToLoad: number,
    menusLoaded: number,
    progress: number,
    routingCompleted: number,
    routerError:
    {
        status: boolean
        code: number,
        message: string
    },
    videoStatus:
    {
        status: boolean,
        id: string
    },
    cachedResponses: Record<string, Ref>
}
export const key: InjectionKey<Store<State>> = Symbol()
export const store = createStore<State>(
{
    state:
    {
        routerQuery: {},
        menusToLoad: 0,
        menusLoaded: 0,
        progress: 0,
        routingCompleted: 0,
        routerError:
        {
            status: false,
            code: 404,
            message: 'Page not found'
        },
        videoStatus:
        {
            status: false,
            id: ''
        },
        cachedResponses: {}
    },
    getters:
    {
        queryState(state)
        {
            return state.routerQuery
        },
        menuState(state)
        {
            return (state.menusToLoad === state.menusLoaded && state.menusLoaded > 0)
        },
        progressState(state)
        {
            return state.progress
        },
        routingCompletion(state)
        {
            return state.routingCompleted
        },
        routerState(state)
        {
            return state.routerError
        },
        videoState(state)
        {
            return state.videoStatus
        },
        getCachedResponseByUrl: (state) => (url: string) =>
        {
            return state.cachedResponses[url]
        }
    },
    mutations:
    {
        setRouterQuery(state, payload)
        {
            state.routerQuery = payload
        },
        recordMenuToLoad(state)
        {
            state.menusToLoad++
        },
        recordMenuLoaded(state)
        {
            state.menusLoaded++
        },
        startProgressEntity(state)
        {
            state.progress++
        },
        finishProgressEntity(state)
        {
            state.progress--
        },
        resetProgressEntity(state)
        {
            state.progress = 0
        },
        recordRoutingCompletion(state)
        {
            state.routingCompleted++
        },
        setRouterError(state, payload)
        {
            state.routerError =
            {
                status: true,
                code: payload.code,
                message: payload.message
            }
        },
        clearRouterError(state)
        {
            state.routerError =
            {
                status: false,
                code: 404,
                message: 'Page not found'
            }
        },
        setVideoStatus(state, payload)
        {
            state.videoStatus =
            {
                status: payload.status,
                id: payload.id
            }
        },
        setCachedResponse(state, payload)
        {
            state.cachedResponses[payload.url] = payload.data
        }
    },
    actions:
    {

    },
    modules:
    {

    }
})
export function useStore()
{
    return baseUseStore(key)
}
