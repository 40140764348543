import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "footer-menu" }
const _hoisted_2 = ["href", "onClick", "target"]
const _hoisted_3 = { class: "link-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuComponent = _resolveComponent("MenuComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_MenuComponent, { type: "secondary" }, {
      default: _withCtx((slotProps) => [
        _createElementVNode("li", null, [
          _createElementVNode("a", {
            href: _ctx.getHref(slotProps),
            onClick: ($event: any) => (_ctx.onNavigate(slotProps, $event)),
            target: _ctx.getTarget(slotProps.item)
          }, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(slotProps.item.name), 1)
          ], 8, _hoisted_2)
        ])
      ]),
      _: 1
    })
  ]))
}