
import { defineComponent } from 'vue'
import MenuComponent from './MenuComponent.vue'
import { MenuStructure, MenuProps } from '@/contracts/templates/MenuContracts'

export default defineComponent(
{
    name: 'MainMenu',
    components:
    {
        MenuComponent
    },
    emits:
    {
        displaySecondaryMenu: null,
        hideSecondaryMenu: null
    },
    methods:
    {
        // Get route name:
        getRouteName(item: MenuStructure)
        {
            // If item is using page path:
            if(item.page && item.path === '')
            {
                return item.page.name
            }
            // If item has it's own path:
            else
            {
                return item.name + 'Menu'
            }
        },
        // Return href:
        getHref(props: MenuProps)
        {
            // Check if link type is external:
            if(props.item.link_type_id == 2)
            {
                return props.item.url
            }
            return props.href
        },
        // Return target:
        getTarget(item: MenuStructure)
        {
            // Check if link type is external:
            if(item.link_type_id === 2 && item.open_in_new_tab)
            {
                return '_blank'
            }
            return '_self'
        },
        // On navigate:
        onNavigate(props: MenuProps, event: MouseEvent)
        {
            // Don't link to contentless pages:
            if(props.item.link_type_id == 1 && props.item.page !== undefined && props.item.page.is_contentless)
            {
                event.preventDefault()
            }
            // Check if link type is external:
            else if(props.item.link_type_id == 1)
            {
                event.preventDefault()
                props.navigate()
                this.hideSecondaryMenu()
            }
        },
        // Emit children elements to display on secondary menu:
        displaySecondaryMenu(children: Array<MenuStructure>)
        {
            this.$emit('displaySecondaryMenu', children)
        },
        // Emit event to hide seconday menu:
        hideSecondaryMenu()
        {
            this.$emit('hideSecondaryMenu')
        }
    }
});
