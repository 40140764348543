
import { defineComponent, PropType } from 'vue'
import { vueVimeoPlayer, Player } from 'vue-vimeo-player'

export default defineComponent(
{
    name: 'VideoComponent',
    components:
    {
        vueVimeoPlayer
    },
    props:
    {
        height: {
            type: Number,
            default: 500
        },
    },
    data()
    {
        return {
            status: false,
            id: ''
        }
    },
    computed: {
        videoWidth(): number
        {
            return window.innerWidth * 0.8
        },
        videoHeight(): number
        {
            return window.innerHeight * 0.8
        },
        // Compute video state:
        videoState(): Record<string, unknown>
        {
            return this.$store.getters.videoState
        }
    },
    created: function()
    {
        // Listen for keyup:
        document.addEventListener('keyup', (e) => this.handleKeyboardClose(e))
    },
    watch:
    {
        // Watch video state:
        videoState(newState)
        {
            this.status = newState.status
            this.id = newState.id
        }
    },
    methods:
    {
        // Handle keyboard close:
        handleKeyboardClose(e: KeyboardEvent)
        {
            if(e.keyCode === 27 && this.status)
            {
                this.closeVideo()
            }
        },
        // Close video:
        closeVideo()
        {
            // Pause video:
            (this.$refs.player as any).pause()
            // Close:
            this.status = false
            // Update:
            this.$store.commit('setVideoStatus',
            {
                status: false,
                id: ''
            })
        }
    }
});
