import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "navbar-nav me-auto"
}
const _hoisted_2 = {
  key: 0,
  class: "nav-item"
}
const _hoisted_3 = ["href", "onClick"]
const _hoisted_4 = ["href", "onClick"]
const _hoisted_5 = {
  key: 1,
  class: "nav-item"
}
const _hoisted_6 = ["href"]
const _hoisted_7 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.showMenu)
    ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuToDisplay, (item, index) => {
          return (_openBlock(), _createBlock(_component_router_link, {
            key: index,
            to: _ctx.getRouteName(item),
            custom: ""
          }, {
            default: _withCtx(({ href, navigate }) => [
              _renderSlot(_ctx.$slots, "default", {
                item: item,
                activeRouteName: _ctx.activeRouteName,
                href: href,
                navigate: navigate
              }, () => [
                (item.link_type_id === 1)
                  ? (_openBlock(), _createElementBlock("li", _hoisted_2, [
                      (_ctx.activeRouteName === _ctx.getRouteName(item))
                        ? (_openBlock(), _createElementBlock("a", {
                            key: 0,
                            href: href,
                            onClick: navigate,
                            class: "nav-link active",
                            "aria-current": "page"
                          }, _toDisplayString(item.name), 9, _hoisted_3))
                        : (_openBlock(), _createElementBlock("a", {
                            key: 1,
                            href: href,
                            onClick: navigate,
                            class: "nav-link"
                          }, _toDisplayString(item.name), 9, _hoisted_4))
                    ]))
                  : (_openBlock(), _createElementBlock("li", _hoisted_5, [
                      (_ctx.activeRouteName === _ctx.getRouteName(item))
                        ? (_openBlock(), _createElementBlock("a", {
                            key: 0,
                            href: item.url,
                            class: "nav-link active",
                            "aria-current": "page"
                          }, _toDisplayString(item.name), 9, _hoisted_6))
                        : (_openBlock(), _createElementBlock("a", {
                            key: 1,
                            href: item.url,
                            class: "nav-link"
                          }, _toDisplayString(item.name), 9, _hoisted_7))
                    ]))
              ])
            ]),
            _: 2
          }, 1032, ["to"]))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}