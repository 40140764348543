import { ref, Ref } from 'vue'
import axios from 'axios'
import { store } from '@/./store'
import { ApiResponse } from '@/contracts/api/ResponseContracts'

export default function axiosGet(url: string): ApiResponse
{
    const data = ref()
    const refresh = async (newUrl = '') =>
    {
        // Check if the refresh URL has changed:
        if(newUrl !== '')
        {
            url = newUrl
        }
        // Start progress state:
        store.commit('startProgressEntity')
        // Check if url has cache entity:
        const cacheEntity = store.getters.getCachedResponseByUrl(url)
        // Check if we can use cached response:
        if(cacheEntity !== undefined)
        {
            // Log in non-production:
            if(process.env.NODE_ENV !== 'production')
            {
                console.log('Cached Response:', cacheEntity)
            }
            setTimeout(function()
            {
                // Set data from cache:
                data.value = cacheEntity
                // Finish progress state:
                store.commit('finishProgressEntity')

            }, 200)
        }
        else
        {
            axios.get(process.env.VUE_APP_API_DOMAIN + url).then((response) =>
            {
                // Log in non-production:
                if(process.env.NODE_ENV !== 'production')
                {
                    console.log(response.data)
                }
                // Set data:
                data.value = response.data.payload
                // Cache the response:
                store.commit('setCachedResponse', {url: url, data: response.data.payload})
                // Finish progress state:
                store.commit('finishProgressEntity')
                // Return response:
                return response
            }).catch(function(error)
            {
                handleError(error, data)
            })
        }
    }

    refresh()

    return {
        data: data,
        refresh
    }
}
export function axiosPost(url: string, formData: Record<string, unknown> = {}): ApiResponse
{
    const data = ref()
    const refresh = async (newUrl = '', formData: Record<string, unknown>) =>
    {
        // Check if the refresh URL has changed:
        if(newUrl !== '')
        {
            url = newUrl
        }
        // Start progress state:
        store.commit('startProgressEntity')

        //alert(JSON.stringify(formData))

        axios.post(process.env.VUE_APP_API_DOMAIN + url, formData).then((response) =>
        {
            // Log in non-production:
            if(process.env.NODE_ENV !== 'production')
            {
                console.log(response.data)
            }
            // Set data:
            data.value = response.data.payload
            // Finish progress state:
            store.commit('finishProgressEntity')
        }).catch(function(error)
        {
            handleError(error, data)
        })
    }

    // Run on load of data exists:
    if(Object.keys(formData).length > 0)
    {
        refresh('', formData)
    }

    return {
        data: data,
        refresh: refresh
    }
}

// Handle error in axios:
function handleError(error: any, data: Ref): void
{
    // Set error data:
    let code = 500
    let message = 'Something went wrong...'
    // Look for response in error:
    if(error.response)
    {
        // Log in non-production:
        if(process.env.NODE_ENV !== 'production')
        {
            //console.log(error.response.data)
            console.log(error.response.status)
            console.log(error.response.headers)
        }
        // Set into error data:
        code = error.response.status
        message = error.response.data.payload
        data.value = message
    }
    // Request was made, but no response received:
    else if(error.request)
    {
        // Log in non-production:
        if(process.env.NODE_ENV !== 'production')
        {
            console.log(error.request)
        }
    }
    // Something happened in setting up the request that triggered an error:
    else
    {
        // Log in non-production:
        if(process.env.NODE_ENV !== 'production')
        {
            console.log('Error', error.message)
        }
    }
    // Log in non-production:
    if(process.env.NODE_ENV !== 'production')
    {
        console.log(error.config)
    }
    // Set router error:
    if(code !== 400)
    {
        store.commit('setRouterError', {code: code, message: message})
    }
    // Finish progress state:
    store.commit('finishProgressEntity')
}
