
import { defineComponent } from 'vue';
import FooterSecondaryMenu from '@/components/menus/FooterSecondaryMenu.vue';
import FooterMenu from '@/components/menus/FooterMenu.vue';

export default defineComponent(
{
    name: 'FooterComponent',
    components:
    {
        FooterSecondaryMenu,
        FooterMenu
    },
    props:
    {
        menuLoaded: Boolean
    },
    data()
    {
        return {
            showSecondaryMenu: true
        }
    }
});
