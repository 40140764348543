import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "nav-item"
}
const _hoisted_2 = ["onMouseenter", "href", "onClick", "id", "target"]
const _hoisted_3 = ["onMouseenter", "href", "onClick", "id", "target"]
const _hoisted_4 = { class: "link-container" }
const _hoisted_5 = ["href", "onClick", "target"]
const _hoisted_6 = ["href", "onClick", "target"]
const _hoisted_7 = {
  key: 1,
  class: "nav-item"
}
const _hoisted_8 = ["href", "onClick", "target"]
const _hoisted_9 = ["href", "onClick", "target"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuComponent = _resolveComponent("MenuComponent")!

  return (_openBlock(), _createBlock(_component_MenuComponent, null, {
    default: _withCtx((slotProps) => [
      ('children' in slotProps.item && slotProps.item.children.length > 0)
        ? (_openBlock(), _createElementBlock("li", _hoisted_1, [
            (slotProps.activeRouteName === _ctx.getRouteName(slotProps.item))
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  onMouseenter: ($event: any) => (_ctx.displaySecondaryMenu(slotProps.item.children)),
                  href: _ctx.getHref(slotProps),
                  onClick: ($event: any) => (_ctx.onNavigate(slotProps, $event)),
                  id: 'link-' + slotProps.item.name,
                  class: "nav-link active",
                  target: _ctx.getTarget(slotProps.item),
                  "aria-current": "page"
                }, _toDisplayString(slotProps.item.name), 41, _hoisted_2))
              : (_openBlock(), _createElementBlock("a", {
                  key: 1,
                  onMouseenter: ($event: any) => (_ctx.displaySecondaryMenu(slotProps.item.children)),
                  href: _ctx.getHref(slotProps),
                  onClick: ($event: any) => (_ctx.onNavigate(slotProps, $event)),
                  id: 'link-' + slotProps.item.name,
                  class: "nav-link",
                  target: _ctx.getTarget(slotProps.item)
                }, _toDisplayString(slotProps.item.name), 41, _hoisted_3)),
            _createVNode(_component_MenuComponent, {
              type: "static",
              items: slotProps.item.children
            }, {
              default: _withCtx((slotPropsChild) => [
                _createElementVNode("li", null, [
                  _createElementVNode("div", _hoisted_4, [
                    (slotPropsChild.activeRouteName === _ctx.getRouteName(slotPropsChild.item))
                      ? (_openBlock(), _createElementBlock("a", {
                          key: 0,
                          href: _ctx.getHref(slotPropsChild),
                          class: "nav-link active",
                          onClick: ($event: any) => (_ctx.onNavigate(slotPropsChild, $event)),
                          target: _ctx.getTarget(slotPropsChild.item)
                        }, _toDisplayString(slotPropsChild.item.name), 9, _hoisted_5))
                      : (_openBlock(), _createElementBlock("a", {
                          key: 1,
                          href: _ctx.getHref(slotPropsChild),
                          class: "nav-link",
                          onClick: ($event: any) => (_ctx.onNavigate(slotPropsChild, $event)),
                          target: _ctx.getTarget(slotPropsChild.item)
                        }, _toDisplayString(slotPropsChild.item.name), 9, _hoisted_6))
                  ])
                ])
              ]),
              _: 2
            }, 1032, ["items"])
          ]))
        : (_openBlock(), _createElementBlock("li", _hoisted_7, [
            (slotProps.activeRouteName === _ctx.getRouteName(slotProps.item))
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  onMouseenter: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.hideSecondaryMenu && _ctx.hideSecondaryMenu(...args))),
                  href: _ctx.getHref(slotProps),
                  onClick: ($event: any) => (_ctx.onNavigate(slotProps, $event)),
                  class: "nav-link active",
                  target: _ctx.getTarget(slotProps.item),
                  "aria-current": "page"
                }, _toDisplayString(slotProps.item.name), 41, _hoisted_8))
              : (_openBlock(), _createElementBlock("a", {
                  key: 1,
                  onMouseenter: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.hideSecondaryMenu && _ctx.hideSecondaryMenu(...args))),
                  href: _ctx.getHref(slotProps),
                  onClick: ($event: any) => (_ctx.onNavigate(slotProps, $event)),
                  class: "nav-link",
                  target: _ctx.getTarget(slotProps.item)
                }, _toDisplayString(slotProps.item.name), 41, _hoisted_9))
          ]))
    ]),
    _: 1
  }))
}